import { cacheCatalogueFilterParams } from '@cache'
import { Container, MobileGolfClubModelSearch } from '@components'
import { Loader } from '@elements'
import { useBrowsePage, useDrawer, useWindowSize } from '@hooks'
import MobileFiltersArrow from '@images/link-arrow.svg'
import Options from '@images/option.svg'
import { BrowseViewComponents } from '@pages/components'
import { useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import '../browse.scss'

interface BrowseViewProps {
  slug: string
}

const BrowseView: React.FC<BrowseViewProps> = props => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { search } = useLocation()
  const { openDrawer, drawer } = useDrawer('MobileBrowseFiltersDrawer')
  const { loading, browsePageData } = useBrowsePage(props.slug)

  console.log('drawer', drawer)

  useEffect(() => {
    cacheCatalogueFilterParams(search)
  }, [search])

  const renderMobileModelSearch = () => {
    if (isMobileScreen)
      return (
        <div className="browse-products-header-mobile-search">
          <MobileGolfClubModelSearch />
        </div>
      )
  }
  const renderDesktopFilters = () => {
    if (!isMobileScreen && browsePage) return <BrowseViewComponents.BrowsePageFilters {...browsePage} />
  }

  const browsePage = browsePageData && browsePageData.res

  if (loading)
    return (
      <div className={'loader'}>
        <Loader />
      </div>
    )
  if (!browsePage) return null

  return (
    <div className={`browse`}>
      <Container>
        <BrowseViewComponents.BrowsePageHeader {...browsePage} />
        {renderMobileModelSearch()}

        <div className={'browse-grid'}>
          {renderDesktopFilters()}
          <BrowseViewComponents.BrowsePageProducts {...browsePage} />
        </div>
      </Container>
    </div>
  )
}

export default BrowseView
